<template>
  <div>
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <main class="p-3">
      <h2 class="section-title">預選清單</h2>
      <p class="total-quantity">您的預選單共計<span class="total-quantity--emphasis">{{ totalQuantity }}</span>項商品</p>
      <div class="products">
        <div
          class="products__item"
          v-for="(quantity, skuId) in cart"
          :key="skuId"
        >
          <img
            :src="getSkuData(skuId, 'image')"
            class="products__item__photo"
          />
          <div class="products__item__title">
            {{ getSkuData(skuId, 'title') }}
          </div>
          <div class="products__item__price" v-if="priceIsVisible">
            <span class="oprice mr-1" v-if="getSkuData(skuId, 'price')">{{ getSkuData(skuId, 'price') }}</span>
            <span class="price">{{ getSkuData(skuId, 'price') }}</span>
          </div>
          <div class="products__item__description" v-html="getSkuData(skuId, 'description')"></div>
          <div class="products__item__quantity">
            <b-form-spinbutton
              size="sm"
              inline
              :value="quantity"
              @change="(quantity) => handleSkuQuantityChange(skuId, quantity)"
              min="1"
            ></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div class="divider mt-4 mb-1"></div>
      <div class="total-price" v-if="priceIsVisible">TOTAL<span class="total-price--emphasis ml-3 mr-2">6,870</span>元</div>

      <h2 class="section-title" style="margin-top: 64px">個人資料</h2>
      <div class="dynamic-form mt-4">
        <DynamicForm ref="checkoutForm" :input-subjects="fields" v-model="inputForm" />
      </div>
    </main>

    <footer class="sticky-bottom footer-action-bar">
      <ShareButton
        @click="$router.push({ name: 'LiffPreorderProducts' })"
        class="footer-action-bar__checkout-button"
        variant="s-btn-outline-primary bg-transparent"
      >
        返回前頁
      </ShareButton>
      <ShareButton
        @click="handleSubmitButtonClicked"
        class="footer-action-bar__checkout-button"
        variant="primary"
      >
        送出訂單
      </ShareButton>
    </footer>
  </div>
</template>

<script>
import preorderApi from "@/apis/liff/v2/preorder";
import DynamicForm from "@/components/DynamicForm/DynamicForm";
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import themeColor from "@/mixins/liff/themeColor";
import _ from "lodash";
import deepGet from "lodash/get";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [themeColor],
  components: {
    DynamicForm,
    ShareButton,
    Loading,
  },
  data() {
    return {
      priceIsVisible: false,
      inputForm: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('liffPreorder', ['totalQuantity']),
    ...mapState('liffPreorder', { preorder: 'configuration', cart: 'cart', skus: 'skus' }),
    preorderId() {
      return this.$route.params.preorderId
    },
    branchId() {
      return this.$route.query.branch_id
    },
    formattedSkus() {
      return this.skus.map(skuModel => {
        const titleSegments = [skuModel.product.title]
        if (skuModel.sku_name !== skuModel.product.title) {
          titleSegments.push(skuModel.sku_name)
        }
        return {
          id: skuModel.id,
          title: titleSegments.join(' '),
          description: _.chain(skuModel).get('product.description').defaultTo('').replace(/\n/g, "<br>"),
          price: skuModel.price,
          image: deepGet(skuModel, 'product.images[0].pic_url', null),
        }
      })
    },
    fields() {
      return deepGet(this.preorder, 'config.fields', []).map(field => ({ id: deepGet(field, 'config._id'), ...field }))
    },
  },
  async mounted() {
    if (Object.keys(this.cart).length === 0) {
      return this.$router.replace({ name: 'LiffPreorderProducts' })
    }
  },
  methods: {
    ...mapActions('liffPreorder', ['clearCart', 'updateCartQuantity']),
    getSkuData(skuId, field) {
      return deepGet(this.formattedSkus.find(sku => sku.id === skuId), field)
    },
    handleSkuQuantityChange(skuId, quantity) {
      this.updateCartQuantity({ skuId, quantity })
    },
    async handleSubmitButtonClicked() {
      const validationPasses = await this.$refs.checkoutForm.validate()
      if (false === validationPasses) {
        return
      }

      const submissionIsConfirmed = await this.$swal({
        text: '確定要送出訂單嗎？',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      })
      if (deepGet(submissionIsConfirmed, 'value', false) === false) {
        return
      }

      this.isLoading = true
      try {
        await preorderApi.create(
          this.preorderId,
          {
            fields: this.inputForm,
            items: Object.keys(this.cart).map(skuId => ({ sku_id: skuId, quantity: this.cart[skuId]})),
            branch_id: this.branchId,
          },
        )
        this.clearCart()
        this.$router.push({ name: 'LiffPreorderFinished' })
      } catch (e) {
        let message = deepGet(e, 'response.data.message', '送出失敗')
        if (typeof message === 'object') {
          message = Object.values(message).join(' ')
        }
        this.$swal.fire({ title: '送出失敗', text: message, type: "error" })
        this.isLoading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  &__item + &__item {
    margin-top: 16px;
  }

  &__item {
    color: #212121;
    background-color: white;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "photo title"
      "photo price"
      "photo description"
      "photo quantity";

    &__photo {
      grid-area: photo;
      width: clamp(120px, 33vw, 240px);
      object-fit: cover;
      aspect-ratio: 1 / 1.2;

      @media screen and (min-width: 512px) {
        width: clamp(120px, 25vw, 240px);
      }
    }

    &__title {
      grid-area: title;
      display: -webkit-box;
      margin-top: 16px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      grid-area: price;
      margin-top: 8px;
      font-size: 0.875rem;
      color: #aaa;
    }

    &__description {
      grid-area: description;
      display: -webkit-box;
      margin-top: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.875rem;
      color: #888;
    }

    &__quantity {
      grid-area: quantity;
      display: flex;
      margin-top: 8px;
      margin-bottom: 16px;
      align-items: flex-end;

      ::v-deep .b-form-spinbutton {
        height: 36px;
        padding: 0;

        output {
          text-align: center !important;
        }
      }
    }

    &__title, &__price, &__description, &__quantity {
      padding: 0 16px;
    }
  }
}

.section-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #444;
  text-align: center;
}

.total-quantity {
  color: #777;
  text-align: center;

  &--emphasis {
    margin: 0 8px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #444;
  }
}

.total-price {
  font-size: .875rem;
  color: #444;
  text-align: right;

  &--emphasis {
    font-size: 1.5rem;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #ccc;
}

.footer-action-bar {
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000D;

  &__checkout-button {
    flex: 0 1 50%;
    padding: 12px;
  }

  &__checkout-button + &__checkout-button {
    margin-left: 16px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
